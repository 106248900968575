import React from "react"
import { Link, graphql } from "gatsby"
import { Helmet } from "react-helmet";

import PostListing from "../components/PostListing/PostListing";

import Layout from "../layout"

const BlogIndex = ({ data, location }) => {
  const siteTitle = 'Parth Patel - a Web Developer'
//   const { edges: posts } = data.allWordpressPost
const posts = data.allMdx.edges;

  return (
    <Layout location={location}>
      <Helmet title={siteTitle} />
      <div className="container max-w-5xl mx-auto px-8 lg:px-0">
      
			<div className="flex flex-wrap justify-between pt-12 leading-tight">
				<div className="w-full mt-6">
					<h3 className="font-normal mt-12">📄 Latest Articles</h3>
          <PostListing postEdges={posts} headerTag="h6"/>
				</div>
			</div>
		
			<div className="font-sans bg-teal-100 rounded my-8 p-4 md:p-24 text-center">
				<h2 className="font-bold break-normal text-2xl md:text-4xl">Join the Newsletter</h2>
				<h3 className="font-bold break-normal font-normal text-gray-600 text-base md:text-xl">I share new tutorials, programming updates and more via newsletter. Subscribe to get updates!</h3>
				<div className="w-full text-center pt-4">
        <a href="https://parthpatel.substack.com/subscribe" className="no-link flex-1 mt-4 md:mt-0 block md:inline-block appearance-none bg-gray-900 text-white text-base font-semibold tracking-wider uppercase py-4 px-8 rounded shadow hover:bg-teal-400">Join the Newsletter</a>
				</div>
			</div>
	</div>
    </Layout>
  )
}

export default BlogIndex

export const indexQuery = graphql`
  query IndexQuery {
    allMdx(sort: { fields: [frontmatter___date], order: DESC }, limit: 20) {
      edges {
        node {
          id
          fields {
            slug
          }
          excerpt
          timeToRead
          frontmatter {
            title
            tags
            coverImage
            date(formatString: "MMM DD, YYYY")
          }
        }
      }
    }
  }
`;